import React, { useEffect, useState } from "react";
import type Stripe from "stripe";
import moment from "moment";
import styled from "styled-components";

import { DialogTitle, DialogActions } from "@material-ui/core";

import { workspaces as workspacesApi } from "apis/callables";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import getLogger, { LogGroup } from "js/core/logger";
import withProgressDialog from "js/core/utilities/withProgressDialog";
import { Button } from "js/Components/Button";

export const logger = getLogger(LogGroup.BILLING);

export interface SwitchBillingIntervalDialogProps {
    billingInterval: "month" | "year";
    closeDialog: () => void;
}

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 30px;
`;

export const SwitchBillingIntervalDialog = WorkspaceController.withInitializedState(BillingController.withInitializedState(
    function SwitchBillingIntervalDialog(props: SwitchBillingIntervalDialogProps & WorkspaceControllerState & BillingControllerState) {
        const { billingInterval, workspace: { id: workspaceId }, stripeData: { subscription }, closeDialog } = props;

        const [upcomingInvoice, setUpcomingInvoice] = useState<Stripe.UpcomingInvoice | null>(null);
        const [ready, setReady] = useState(false);

        useEffect(() => {
            (async () => {
                const upcomingInvoice = await workspacesApi.previewUpcomingInvoiceForBillingInterval({ workspaceId: workspaceId, billingInterval });
                setUpcomingInvoice(upcomingInvoice);
                setReady(true);
            })();
        }, [billingInterval]);

        let invoiceTotal = 0;
        if (upcomingInvoice) {
            invoiceTotal = upcomingInvoice.total;
            if (upcomingInvoice.discount && billingInterval === "month" && upcomingInvoice.discount.end > upcomingInvoice.period_end) {
                // Remove discount if it will have ended by the end of the year
                invoiceTotal += upcomingInvoice.total_discount_amounts[0].amount;
            }
        }
        invoiceTotal = invoiceTotal / 100;

        const handleSwitchBillingInterval = async () => {
            try {
                await withProgressDialog({
                    title: `Switching to ${billingInterval === "month" ? "monthly" : "yearly"} billing...`,
                    action: () => BillingController.switchSubscriptionBillingInterval(billingInterval)
                });
                closeDialog();
            } catch (err) {
                logger.error(err, "[SwitchBillingIntervalDialog] handleSwitchBillingInterval() failed");

                ShowErrorDialog({
                    error: "An error occurred while switching your billing interval",
                    message: (
                        <>
                            <p><strong>Error: </strong>{err.message}</p>
                            <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                        </>
                    )
                });
            }
        };

        const currentPeriodEnd = moment.unix(upcomingInvoice?.period_end).format("MMM DD YYYY");
        const currentBillingInterval = subscription.current_billing_interval;

        return (<BeautifulDialog closeDialog={closeDialog} closeButton>
            <DialogTitle data-test-id="switch-billing-interval-dialog-title">Switch to {billingInterval === "month" ? "monthly" : "annual"} billing?</DialogTitle>
            <DialogContent data-test-id="switch-billing-interval-dialog-content">
                <FetchingClickShield visible={!ready} backgroundColor="#eee" />
                {subscription.status === "trialing" && <>
                    Your trial will be continued. Your next
                    bill of <strong>${invoiceTotal.toFixed(2)}</strong> will be due on <strong>{currentPeriodEnd}</strong>.
                </>}
                {subscription.status === "active" && <>
                    {subscription.scheduled_billing_interval === currentBillingInterval && <>
                        {billingInterval === "year" && <>
                            You will be billed <strong>${invoiceTotal.toFixed(2)}</strong> today, including a proration for the current month.
                        </>}
                        {billingInterval === "month" && <>
                            You will be billed <strong>${invoiceTotal.toFixed(2)}</strong>/month when your yearly plan ends on <strong>{currentPeriodEnd}</strong>.
                        </>}
                    </>}
                    {subscription.scheduled_billing_interval !== currentBillingInterval && <>
                        You will remain on {currentBillingInterval} billing instead of switching to {billingInterval}.
                    </>}
                </>}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} unframed large>
                    Cancel
                </Button>
                <Button onClick={handleSwitchBillingInterval} blue large data-test-id="switch-billing-interval-dialog-confirm-button">
                    Confirm
                </Button>
            </DialogActions>
        </BeautifulDialog>);
    }
));
