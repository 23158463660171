import React, { Component } from "react";
import styled from "styled-components";

import { DialogActions, DialogTitle, Select, MenuItem, RadioGroup, Radio, FormControlLabel, Button } from "@material-ui/core";

import { BeautifulDialog, DialogContent, ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { UserProfile } from "js/react/components/Avatar";

const MessageText = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #333333;
`;

const BoldMessageText = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    margin-bottom: 20px;
`;

const ContentContainer = styled.div`
    margin-top: 15px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    >div.MuiFormGroup-root {
        flex-direction: column;
        align-items: center;

        >label {
            width: 100%;
        }

        >div>fieldset {
            border-color: #f3f3f3;
        }
    }
`;

const UserProfileContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
`;

const UserSelectContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 28px;

    >div.MuiInputBase-root {
        width: 350px;
    }
`;

export default class RemoveMemberFromOrgDialog extends Component {
    state = {
        transferOption: "other",
        newOwner: { email: "Select team member" }
    };

    get memberDisplayName() {
        const { member } = this.props;
        return member.displayName || member.email;
    }

    handleChange = event => {
        if (["self", "other", "revoke"].includes(event.target.value)) {
            this.setState({
                transferOption: event.target.value,
                newOwner: event.target.value !== "other" ? { email: "Select team member", id: null } : this.state.newOwner
            });
        }
    };

    handleAcceptButton = () => {
        const { member, closeDialog, onAccept } = this.props;
        const { newOwner, transferOption } = this.state;

        let acceptCallback = () => {
            onAccept(member, newOwner, transferOption);
            closeDialog();
        };

        if (transferOption === "revoke") {
            ShowConfirmationDialog({
                title: "Are you sure you want to delete this user's presentations?",
                message: `Any presentations this user owns in this workspace will be deleted and any collaboration permissions revoked. This action cannot be undone.`,
                okButtonLabel: "Delete Presentations",
                acceptCallback,
            });
        } else {
            acceptCallback();
        }
    }

    handleSelectChange = event => {
        const { allMembers } = this.props;
        const selectedMember = allMembers.find(member => member.id === event.target.value) || { email: "Select team member", id: null };
        this.setState({
            newOwner: selectedMember,
            transferOption: selectedMember.id ? "other" : this.state.transferOption
        });
    }

    render() {
        const { allMembers, member, closeDialog } = this.props;
        const { newOwner, transferOption } = this.state;

        const filteredMembers = allMembers
            .filter(({ id, email }) => id && member.email !== email)
            .map(member => ({
                email: member.email,
                id: member.id,
                role: member.role
            }))
            .sort((a, b)=>a.email.localeCompare(b.email));

        return (
            <BeautifulDialog PaperProps={{ style: { maxWidth: 550 } }}>
                <DialogTitle>Remove member?</DialogTitle>
                <DialogContent>
                    <MessageText>
                        This user will no longer have access to shared Team Resources.
                    </MessageText>
                    <UserProfileContainer>
                        <UserProfile profile={member} />
                    </UserProfileContainer>
                    <hr />
                    <ContentContainer>
                        <BoldMessageText>What would you like to do with this user’s presentations?</BoldMessageText>
                        <RadioGroup
                            value={transferOption}
                            onChange={this.handleChange}
                        >
                            <FormControlLabel
                                value="self"
                                control={<Radio color="primary" />}
                                label={"Transfer presentations to their personal workspace"}
                            />
                            <FormControlLabel
                                value="other"
                                control={<Radio color="primary" />}
                                label={"Transfer presentations to: "}
                            />
                            <UserSelectContainer>
                                <Select
                                    SelectDisplayProps={{ style: { textTransform: "unset" } }}
                                    variant="outlined"
                                    disableUnderline
                                    onChange={this.handleSelectChange}
                                    value={newOwner.email}
                                    renderValue={value => value}
                                >
                                    {filteredMembers.map(member => (
                                        <MenuItem key={member.id} value={member.id}>
                                            {member.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </UserSelectContainer>
                            <FormControlLabel
                                value="revoke"
                                control={<Radio color="primary" />}
                                label="Delete presentations and revoke collaboration permissions"
                            />
                        </RadioGroup>
                    </ContentContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog} style={{ marginRight: 30 }}>
                        Nevermind
                    </Button>
                    <Button color="primary" disabled={transferOption === "other" && !newOwner.id} onClick={this.handleAcceptButton}>
                        Remove member
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
