import React from "react";
import styled from "styled-components";

import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { WorkspacePlanTier } from "common/interfaces";

import { CTABlock } from "./Components/CTABlock";
import { CurrentPlanBlock } from "./Components/CurrentPlanBlock";
import { PaymentMethodBlock } from "./Components/PaymentMethodBlock";
import { PaymentHistory } from "./Components/PaymentHistory";

const Container = styled.div`
    color: #333;
    position: relative;
    background: #eee;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
`;

const Header = styled.div`
    width: 100%;
    height: 50px;
    background: #eee;
    color: #222;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
`;

const ContentContainer = styled.div`
    padding: 4px 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ContentBlocksContainer = styled.div`
    display: grid;
    column-gap: 20px;
    grid-auto-flow: column;
`;

export default BillingController.withInitializedState(WorkspaceController.withInitializedState(function BillingPane(props: WorkspaceControllerState & BillingControllerState) {
    const { plan } = props;

    return (<Container>
        <Header>{[WorkspacePlanTier.ENTERPRISE, WorkspacePlanTier.TEAM].includes(plan.tier) ? "Team Billing" : "Billing"}</Header>
        <ContentContainer>
            <ContentBlocksContainer>
                <CurrentPlanBlock />
                <PaymentMethodBlock />
                <CTABlock />
            </ContentBlocksContainer>
            <PaymentHistory />
        </ContentContainer>
    </Container>);
}));
