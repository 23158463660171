import moment from "moment";
import React from "react";
import styled from "styled-components";

import { Button } from "js/Components/Button";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import AppController, { AppControllerState } from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { getPricingPageUrl } from "js/core/utilities/pricing";
import { ShowConfirmationDialog, ShowDialog, ShowDialogAsync, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";
import withProgressDialog from "js/core/utilities/withProgressDialog";
import { ShowTypeformDialog } from "js/react/components/Dialogs/TypeformDialog";
import { EndTrialDialog } from "js/react/views/UserOptions/Billing/EndTrialDialog";
import { TYPEFORM_IDS } from "common/constants";
import { WorkspacePlanTier } from "common/interfaces";

import { SwitchBillingIntervalDialog } from "../../../Billing/SwitchBillingIntervalDialog";
import { BlockButtonsContainer, BlockContainer, BlockContentContainer, BlockHeader } from "./Containers";
import { ChangePaymentMethodDialog } from "../../../Billing/ChangePaymentMethodDialog";

export const logger = getLogger(LogGroup.BILLING);

const PlanName = styled.div`
    font-size: 36px;
    font-weight: 600;
    color: #222;
    text-transform: capitalize;
`;

const PlanSubtitle = styled.div`
    font-size: 16px;
    color: #333;
`;

const PlanTextButton = styled.div`
    font-size: 14px;
    font-weight: 900;
    color: ${themeColors.ui_blue};
    cursor: pointer;
    text-decoration: underline;
`;

const InfoText = styled.div`
    font-size: 15px;
    color: #333;

    a {
        color: ${themeColors.ui_blue};
    }
`;

const CurrentPlanBlockContentWithoutSubscription = AppController.withState(WorkspaceController.withInitializedState(BillingController.withInitializedState(
    function CurrentPlanBlockWithoutSubscription(props: WorkspaceControllerState & BillingControllerState & AppControllerState) {
        const { plan, user } = props;

        const handleUpgradePlan = () => {
            window.open(getPricingPageUrl(!!user.hasTakenTrial, !!user.get("hasTakenTeamTrial"), plan.tier), "_blank");
        };

        return (<>
            <BlockContentContainer left>
                <PlanName data-test-id="plan-name">{props.plan.name} (Free)</PlanName>
            </BlockContentContainer>
            <BlockButtonsContainer>
                <Button warning onClick={handleUpgradePlan}>Upgrade Plan</Button>
            </BlockButtonsContainer>
        </>);
    })
));

const CurrentPlanBlockContentWithSubscription = AppController.withState(WorkspaceController.withInitializedState(BillingController.withInitializedState(
    function CurrentPlanBlockWithSubscription(props: WorkspaceControllerState & BillingControllerState & AppControllerState) {
        const { stripeData: { subscription }, plan, user } = props;

        const billingInterval = subscription.items.data[0]?.price.recurring?.interval ?? null;
        const subscriptionCanceled = subscription.cancel_at_period_end;
        const isInTrial = subscription.status === "trialing";
        const daysRemainingInTrial = isInTrial ? Math.ceil(
            (subscription.trial_end * 1000 - new Date().getTime()) / 1000 / 60 / 60 / 24
        ) : null;
        const trialStartedMinutesAgo = isInTrial ? moment().diff(moment(subscription.created * 1000), "minutes") : 0;
        const nextBillDate = moment.unix(subscription.current_period_end).format("MMM DD, YYYY");
        const scheduledBillingInterval = subscription.scheduled_billing_interval;

        const handleCancelPlan = async () => {
            const planName = isInTrial ? "trial" : `${plan.name} plan`;

            const accepted = await ShowConfirmationDialog({
                title: `Are you sure you want to cancel your ${planName}?`,
                message: <>
                    <p>Your plan will remain active until the end of the {isInTrial ? "trial period" : "current billing cycle"} on {nextBillDate} after which you will be downgraded to the Basic (free) plan.</p>
                    <p>Please ensure your team transfers any presentations you'd like to continue to access to another workspace before the end of your trial.</p>
                </>,
                cancelButtonLabel: "Never mind",
                okButtonLabel: `Cancel ${planName}`
            });

            if (!accepted) {
                return;
            }

            try {
                await withProgressDialog({
                    title: `Canceling ${planName}...`,
                    action: () => BillingController.cancelSubscription()
                });

                ShowTypeformDialog({
                    typeformId: TYPEFORM_IDS.CHURN_SURVEY,
                    hiddenFields: {
                        user_id: user.id,
                    },
                });
            } catch (err) {
                logger.error(err, "[CurrentPlanBlock] handleCancelPlan() failed");

                ShowErrorDialog({
                    error: `An error occurred while canceling your ${planName}`,
                    message: (
                        <>
                            <p><strong>Error: </strong>{err.message}</p>
                            <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                        </>
                    )
                });
            }
        };

        const handleReactivatePlan = async () => {
            try {
                if (!subscription.default_payment_method || subscription.status === "past_due") {
                    const shouldReactivate = await ShowDialogAsync(ChangePaymentMethodDialog);
                    if (!shouldReactivate) {
                        return;
                    }
                }

                await withProgressDialog({
                    title: `Reactivating ${plan.name} plan...`,
                    action: () => BillingController.reactivateSubscription()
                });
            } catch (err) {
                logger.error(err, "[CurrentPlanBlock] handleReactivatePlan() failed");

                ShowErrorDialog({
                    error: `An error occurred while reactivating your ${plan.name} plan`,
                    message: (
                        <>
                            <p><strong>Error: </strong>{err.message}</p>
                            <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                        </>
                    )
                });
            }
        };

        const handleSwitchBillingInterval = async (billingInterval: "month" | "year") => {
            ShowDialog(SwitchBillingIntervalDialog, { billingInterval });
        };

        const handleEndTrial = () => {
            ShowDialog(EndTrialDialog);
        };

        return (<>
            <BlockContentContainer left dense>
                <PlanName data-test-id="plan-name">{props.plan.name} {billingInterval ? `(${billingInterval === "month" ? "Monthly" : "Annual"})` : ""}</PlanName>
                {subscriptionCanceled && <PlanSubtitle data-test-id="plan-canceled-notice">Subscription canceled</PlanSubtitle>}
                {plan.tier !== WorkspacePlanTier.ENTERPRISE && <>
                    {billingInterval !== scheduledBillingInterval && <PlanSubtitle data-test-id="scheduled-billing-interval-notice">Scheduled to switch to {scheduledBillingInterval === "month" ? "monthly" : "annual"} billing</PlanSubtitle>}
                    {scheduledBillingInterval === "year" &&
                        <PlanTextButton data-test-id="switch-to-monthly-billing-button" onClick={() => handleSwitchBillingInterval("month")}>
                            Switch to monthly billing
                        </PlanTextButton>
                    }
                    {scheduledBillingInterval === "month" && <>
                        {billingInterval === "month" &&
                            <PlanTextButton data-test-id="switch-to-annual-billing-button" onClick={() => handleSwitchBillingInterval("year")}>
                                Save with annual billing
                            </PlanTextButton>
                        }
                        {billingInterval !== "month" &&
                            <PlanTextButton data-test-id="switch-to-annual-billing-button" onClick={() => handleSwitchBillingInterval("year")}>
                                Switch back to annual billing
                            </PlanTextButton>
                        }
                    </>}
                </>}
                {isInTrial && <PlanSubtitle data-test-id="trial-info">You have {daysRemainingInTrial} day{daysRemainingInTrial === 1 ? "" : "s"} remaining in your free trial</PlanSubtitle>}
            </BlockContentContainer>
            <BlockButtonsContainer>
                {plan.tier === WorkspacePlanTier.ENTERPRISE &&
                    <InfoText data-test-id="enterprise-contact-us-text">Contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a> to cancel your {plan.name} {isInTrial ? "trial" : "plan"}</InfoText>
                }
                {plan.tier !== WorkspacePlanTier.ENTERPRISE && <>
                    {!subscriptionCanceled && <>
                        {isInTrial && <>
                            <Button large unframed onClick={handleCancelPlan} data-test-id="cancel-trial-button">Cancel Free Trial</Button>
                            {trialStartedMinutesAgo > 10 && <Button large blue onClick={handleEndTrial}>Start Subscription Now</Button>}
                        </>}
                        {!isInTrial && <>
                            <Button large unframed onClick={handleCancelPlan} data-test-id="cancel-plan-button">Cancel Plan</Button>
                        </>}
                    </>}
                    {subscriptionCanceled && <>
                        <Button large warning onClick={handleReactivatePlan} data-test-id="reactivate-plan-button">Reactivate {plan.name} Plan</Button>
                    </>}
                </>}
            </BlockButtonsContainer>
        </>);
    }
)));

export const CurrentPlanBlock = WorkspaceController.withInitializedState(
    function CurrentPlanBlock(props: WorkspaceControllerState) {
        const { plan } = props;

        return (<BlockContainer data-test-id="current-plan-block">
            <BlockHeader>Current Plan</BlockHeader>

            {plan.requiresSubscription && <CurrentPlanBlockContentWithSubscription />}
            {!plan.requiresSubscription && <CurrentPlanBlockContentWithoutSubscription />}
        </BlockContainer>);
    }
);
